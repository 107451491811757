import { toast } from 'react-toastify';
import { maxLengthChecksForListjob } from '../data/data';

export const validateListJobInputs = (data, selectedTechStack) => {
    const requiredFields = [
        'name', 'email', 'mobile', 'projectTitle', 'projectType', 'description', 'requirements', 'responsibilities', 'selectedTechStack'
    ];

    for (let field of requiredFields) {
        if (!data[field] || (typeof data[field] === 'string' && data[field].trim() === '')) {
            toast.warning(`Please fill the ${field} field.`);
            return false;
        }
    }



    for (let field in maxLengthChecksForListjob) {
        if (data[field] && data[field].length > maxLengthChecksForListjob[field]) {
            toast.error(`${field} input cannot be more than ${maxLengthChecksForListjob[field]} characters.`);
            return false;
        }
    }

    const projectTitleRegex = /^[a-zA-Z0-9\s+]+$/;
    if (!projectTitleRegex.test(data.projectTitle) || data.projectTitle.includes('   ')) {
        toast.error('Project Title cannot have three consecutive spaces or special characters.');
        return false;
    }

    if (data.budget.length > 20) {
        toast.error('Budget cannot be more than 20 digits long.');
        return false;
    }

    if (selectedTechStack.length === 0) {
        toast.warning("Please select at least one skill required for the project.");
        return false;
    }

    return true;
};
