import React from 'react';
import './ScrollableModal.css';

const ScrollableModal = ({
    show,
    heading,
    body,
    onClose,
    primaryButtonText,
    secondaryButtonText,
    onPrimaryButtonClicked,
    onSecondaryButtonClicked,
    showButtons,
}) => {
    if (!show) return null;

    return (
        <div className="scrollable_modal_overlay">
            <div className="scrollable_modal_wrapper">
                <article className="scrollable_modal_container">
                    <header className="scrollable_modal_header">
                        <span className="scrollable_modal_title">
                            {heading}
                        </span>
                        <button className="scrollable_modal_close_button" onClick={onClose}>
                            <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" fill="currentColor"></path>
                            </svg>
                        </button>
                    </header>
                    <section className="scrollable_modal_body scrollable_modal_rich_text">
                        {body}
                    </section>
                    {showButtons && (
                        <footer className="scrollable_modal_footer">
                            <button className="scrollable_modal_button scrollable_modal_button_ghost" onClick={onSecondaryButtonClicked}>
                                {secondaryButtonText}
                            </button>
                            <button className="scrollable_modal_button scrollable_modal_button_primary" onClick={onPrimaryButtonClicked}>
                                {primaryButtonText}
                            </button>
                        </footer>
                    )}
                </article>
            </div>
        </div>
    );
};

export default ScrollableModal;