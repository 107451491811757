import React from 'react';

const PriceIndicator = ({ price }) => {
    const activeColor = "#429134"; // Green color for active dollar signs
    const inactiveColor = "#d3d3d3"; // Grey color for inactive dollar signs
    const blackColor = "#000000"; // Black color for "FREE" text

    if (price === 0) {
        return (
            <div style={{ display: 'flex', gap: '1px' }}>
                <span
                    style={{
                        color: blackColor,
                        fontWeight: 'bold'
                    }}
                >
                    FREE
                </span>
            </div>
        );
    } else {
        return (
            <div style={{ display: 'flex', gap: '1px' }}>
                {[...Array(4)].map((_, index) => (
                    <span
                        key={index}
                        style={{
                            color: index < price ? activeColor : inactiveColor,
                            fontWeight: 'bold'
                        }}
                    >
                        $
                    </span>
                ))}
            </div>
        );
    }
};

export default PriceIndicator;
