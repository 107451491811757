import React from 'react';
import Client01 from '../../images/trusted-by/client-01.png';
import Client04 from '../../images/trusted-by/client-04.png';
import Client06 from '../../images/trusted-by/client-06.png';
import Client02 from '../../images/trusted-by/client-02.png';
import Client09 from '../../images/trusted-by/client-09.png';

const TrustedBySection = () => {
    return (
        <section>
            <div className="cfd1l cnsl7 cwkz1 cwkio">
                <div className="cokkt czhnl c0qn4 c4tzn">
                    {/* Items */}
                    <div className="c8og8 cvkqj cduop ckxup czbam">
                        {/* Trusted by */}
                        <div className="cn582 ce33e cp9oo cujho cpiyw">
                            <div className="ch6sm c1laq cu7if cw2fq">Trusted by</div>
                        </div>
                        {/* Item */}
                        <div className="cz79n ce33e cp9oo czt4f cujho cpiyw">
                            <img src={Client01} alt="Client01" width="114" height="64" />
                        </div>
                        {/* Item */}
                        <div className="cz79n ce33e cp9oo czt4f cujho cpiyw">
                            <img src={Client06} alt="Client06" width="66" height="64" />
                        </div>
                        {/* Item */}
                        <div className="cz79n ce33e cp9oo czt4f cujho cpiyw">
                            <img src={Client04} alt="Client04" width="114" height="64" />
                        </div>
                        {/* Item */}
                        <div className="cz79n ce33e cp9oo czt4f cujho cpiyw">
                            <img src={Client02} alt="Client08" width="125" height="64" />
                        </div>
                        {/* Item */}
                        <div className="cz79n cykk9 czt4f cujho">
                            <img src={Client09} alt="Client09" width="114" height="64" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TrustedBySection;
