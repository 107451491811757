import React from 'react';
import './NotificationCard.css';

const Notification = () => {
    return (
        <div className="notification">

            <div className="text">
                <div className="text-top">
                    <p>
                        <span className="profil-name">Lorem ipsum dolor sit amet</span> reacted to your recent post <b>Lorem ipsum dolor sit amet</b>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus eligendi exercitationem sequi est excepturi
                        laudantium omnis in. Facilis iste, similique, ipsa necessitatibus nihil distinctio fugiat recusandae perspiciatis
                        voluptas repellendus doloremque?
                    </p>
                </div>
                <div className="text-bottom">1m ago</div>
            </div>
        </div>
    );
};

export default Notification;
