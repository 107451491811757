import React, { useState } from 'react';
import logo from '../images/logo2.svg';
import SideTestimonial from '../components/SideTestimonials/SideTestimonials';
import { sideTestimonialListJobData } from '../data/data';
import Modal from '../components/General/Modal/Modal';
import { auth } from '../firebaseConfig/Firebase';
import { sendPasswordResetEmail, sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const ForgetPassword = () => {

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalHeading, setModalHeading] = useState('');
    const [email, setEmail] = useState('');

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            setModalHeading("Password Reset Email Sent");
            setModalMessage("A link to reset your password has been sent to your email address.");
            setShowModal(true);
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                setModalHeading("Email Not Found");
                setModalMessage("No such email exists in our database. Please create an account with this email first.");
            } else if (error.code === 'auth/user-not-verified') {
                setModalHeading("Email Not Verified");
                setModalMessage("The email is not yet verified. Please verify your email before resetting your password.");
            } else {
                setModalHeading("Password Reset Failed");
                setModalMessage(`An error occurred: ${error.message}`);
            }
            setShowModal(true);
        }
    };

    return (
        <div>
            {/* Content */}
            <div className="cbrwb cqmv3 cn582">

                <div className="cau4d">

                    <div className="cbjuw cvcg6 c48gu ca7zr cfd1l cau4d cn582 c8og8 cmcfj">

                        {/* Site header */}
                        <header className="clnaa ce3tb c8og8">
                            <div className="chu3i cduop c1ymg c8og8 caqh0">
                                {/* Logo */}
                                <div class="ch3n4 ctzpc">
                                    {/* <!--  Logo --> */}
                                    <a class="cohq9 c6kou" href="index.html" aria-label="Logo">
                                        <img src={logo} alt="Logo" />
                                    </a>

                                </div>
                                <span className="c1laq chgw8 cw2fq"><b>Project Mela</b></span>
                            </div>
                        </header>

                        <div className="ce3tb coh2n">

                            <div className="c6cl6">
                                <h1 className="cmz24 cuad0 cgmpd cmi0k">Password Reset</h1>
                                <div className="ca6yp">Forgot your password ? Don't worry we got you covered. Just enter the email of your account, we will send you an email on your verified email account through which you can reset your password.</div>
                            </div>

                            {/* Form */}
                            <form onSubmit={handleResetPassword} >
                                <div className="cbo0x">
                                    <div>
                                        <label className="c4vrg cf37z cohq9 ck6q6" htmlFor="email">Email</label>
                                        <input id="email" className="cule2 cn582" type="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                {/* Password input with see and hide password toggle */}

                                <div className="cfait">
                                    <button className="czwz3 ce7qj cyky3 ctpla cn582 c6kou c0tyr" onClick={handleResetPassword}>
                                        <b>Reset Password</b> <span className="c8bku clfy9 ccolo cndda comjk cmwpt ce3ah">➜</span>
                                    </button>
                                </div>
                            </form>

                        </div>

                    </div>

                </div>

            </div>

            {/* Right side */}

            <SideTestimonial testimonials={sideTestimonialListJobData} />

            <Modal
                show={showModal}
                onClose={() => {
                    setShowModal(false);
                    navigate('/signin');
                }}
                heading={modalHeading}
                body={modalMessage}
                primaryButtonText="OK"
                secondaryButtonText="Cancel"
                showButtons={false}
                onPrimaryClick={() => setShowModal(false)}
                onSecondaryClick={() => setShowModal(false)}


            />




        </div>
    );
};

export default ForgetPassword;