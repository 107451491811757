import React from 'react';
import testimonial01 from '../../images/home/testimonial-01.jpg';
import testimonial02 from '../../images/home/testimonial-02.jpg';


const Testimonials = () => {
    return (
        <div>
            <h2 className="c6cl6 cuad0 cfibj clt06">Our users love us</h2>
            {/* Testimonials container */}
            <div className="c2i7x">
                {/* Testimonial 1 */}
                <div className="c7z1d cqwhl ch4ps ch7jl ck73f cww2f comjk cmwpt casko csmwo cao76">
                    <div className="c8og8 cduop cv4ej">
                        <div className="cwq9z ctzpc">
                            <img
                                className="c1g5q"
                                src={testimonial01}
                                width="102"
                                height="102"
                                alt="Testimonial 01"
                            />
                            <svg
                                className="c6kdq cyzod c753i cflao"
                                width="26"
                                height="17"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M0 16.026h8.092l6.888-16h-4.592L0 16.026Zm11.02 0h8.092L26 .026h-4.65l-10.33 16Z"></path>
                            </svg>
                        </div>
                        <figure>
                            <blockquote className="cg9ly cmn18 cqdqx clt06">
                                <p>
                                    This platform completed our project effortlessly and hassle free on time and exceeded expectations!
                                </p>
                            </blockquote>
                            <figcaption className="cf37z c4vrg">
                                Roy Khanna, CEO <a className="c0bjb c4von cu1zo" >Forexfox.com</a>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                {/* Testimonial 2 */}
                <div className="c7z1d cqwhl crhpi c0s3t ck73f cww2f comjk cmwpt casko csmwo cao76">
                    <div className="c8og8 cduop cv4ej">
                        <div className="cwq9z ctzpc">
                            <img
                                className="c1g5q"
                                src={testimonial02}
                                width="102"
                                height="102"
                                alt="Testimonial 02"
                            />
                            <svg
                                className="c6kdq cyzod c753i cflao"
                                width="26"
                                height="17"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M0 16.026h8.092l6.888-16h-4.592L0 16.026Zm11.02 0h8.092L26 .026h-4.65l-10.33 16Z"></path>
                            </svg>
                        </div>
                        <figure>
                            <blockquote className="cg9ly cmn18 cqdqx clt06">
                                <p>
                                    Thanks to this platform, I earned money while in college and gained invaluable experience working on real-world tech projects.
                                </p>
                            </blockquote>
                            <figcaption className="cf37z c4vrg">
                                Mohit Gupta, Student <a className="cpjz5 c4von cu1zo">IIT Bombay</a>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
