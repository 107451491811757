

import React from 'react';
import './SearchBar.css';


const SearchBar = ({
    showFilterOptions = false,
    onClickFilterOptions = () => { },
    onClickSortOptions = () => { },
    placeholder = "Search by....",
    value = "",
    onChange = () => { },
    showFilterDot = false,
    showSortDot = false,
}) => {

    return (
        <div className="searchbar-container">

            <div className="searchbar-input-container">

                {/* Search Icon and Search input box group */}

                <div className="searchbar-input-group">


                    <label htmlFor="input" className="searchbar-label">
                        <svg viewBox="0 0 512 512" className="searchbar-icon">
                            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
                        </svg>
                    </label>


                    <input
                        type="text"
                        name="text"
                        className="searchbar-input"
                        id="input"
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                    />

                </div>


                {/* Filter and Sort Button Group */}
                <div className="searchbar-button-group">
                    {showFilterOptions && (
                        <>
                            <div className="searchbar-button-separator"></div>


                            <button className="searchbar-filter-button" onClick={onClickFilterOptions}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#727272" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
                                {showFilterDot && <span className="dot-indicator"></span>}
                            </button>

                        </>

                    )}

                    <div className="searchbar-button-separator"></div>

                    <button className="searchbar-sort-button" onClick={onClickSortOptions}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#727272" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 9.5H3M21 4.5H3M21 14.5H3M17 19.5H3" /></svg>
                        {showSortDot && <span className="dot-indicator"></span>}
                    </button>
                </div>

            </div>
        </div>


    );
};

export default SearchBar;


