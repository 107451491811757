import React from 'react';
import './LabelChip.css';

const Chip = ({ text, color }) => {
    const getTextByColor = (color) => {
        switch (color) {
            case 'green':
                return 'Open';
            case 'red':
                return 'Closed';
            case 'orange':
                return 'Ongoing';
            case 'blue':
                return 'Request';
            case 'grey':
                return 'Inquiry';
            default:
                return text;
        }
    };

    return (
        <div className={`chip ${color}`}>
            <b>{getTextByColor(color)}</b>
        </div>
    );
};

export default Chip;
