import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { db, auth } from '../firebaseConfig/Firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import companyIcon08 from '../images/job-detail/company-icon-08.svg';
import companyIcon04 from '../images/job-detail/company-icon-04.svg';
import companyIcon11 from '../images/job-detail/company-icon-11.svg';
import NewsletterCTA from '../components/NewsletterCTA/NewsletterCTA';
import JobCard from '../components/JobCard/JobCard';
import Chip from '../components/LabelChip/LabelChip';
import PriceIndicator from '../components/PriceIndicator/PriceIndicator';
import point from "../images/points.png";
import SocialShare from '../components/SocialShare/SocialShare';
import Loader from '../components/Loader/Loader';
import { formatFullDate } from '../utils/dateFormatting';
import { getChipColorFromStatus, getPriceRating } from '../utils/otherFunctions';
import Modal from '../components/General/Modal/Modal';

const JobDetail = () => {

    const [showModal, setShowModal] = useState(false);
    const [modalHeading, setModalHeading] = useState('');
    const [modalBody, setModalBody] = useState('');
    const navigate = useNavigate();
    const { jobID } = useParams();
    const [jobData, setJobData] = useState(null);

    useEffect(() => {
        const fetchJobData = async () => {
            const jobDoc = doc(db, 'Projects', jobID); // Adjust collection name as needed
            const jobSnapshot = await getDoc(jobDoc);

            if (jobSnapshot.exists()) {
                setJobData(jobSnapshot.data());
            } else {
                console.error('No such document!');
            }
        };

        fetchJobData();
    }, [jobID]);

    const handleApply = async (jobID, jobData) => {
        const user = auth.currentUser; // Access the authenticated user directly

        if (user) {
            const userEmail = user.email;

            // Fetch user data from the Users collection
            const userDoc = doc(db, 'Users', userEmail);
            const userSnapshot = await getDoc(userDoc);

            if (userSnapshot.exists()) {
                const userData = userSnapshot.data();
                const userXP = userData.XP;
                const isProfileCompleted = userData.isProfileCompleted;


                // Check if profile is completed
                if (!isProfileCompleted) {
                    // Show warning toast for incomplete profile
                    toast.warning('Please complete your profile to apply for Projects');
                    return;
                }

                // Check if user's XP is greater than minXP
                if (userXP < jobData.minXP) {
                    // Show error toast for insufficient XP
                    toast.error('Your XP is below the minimum required XP to do this project');
                    return;
                }

                const documentID = `${userEmail}---${jobID}`; // Create a unique document ID

                const applicationData = {
                    projectName: jobData.projectTitle,
                    projectID: jobID,
                    ApplicantEmail: user.email,
                    submittedOn: new Date(), // Current timestamp
                    isApproved: false,
                    isDeleted: false,
                };

                try {
                    // Use setDoc with doc to save the data in Firestore
                    await setDoc(doc(db, 'Applications', documentID), applicationData);

                    setModalHeading('Submitted  ✅');
                    setModalBody('Application submitted successfully!');
                } catch (error) {
                    console.error('Error submitting application: ', error);
                    setModalHeading('Error');
                    setModalBody('Error submitting application: ' + error.message);
                } finally {
                    setShowModal(true);
                }
            } else {
                console.error('User document does not exist');
            }
        } else {
            console.error('User is not authenticated');
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        navigate('/'); // Navigate to home page on modal close
    };



    useEffect(() => {
        window.scrollTo(0, 100); // Scroll almost to the top, one scroll below the top
    }, []);

    if (!jobData) {
        return <Loader />; // Show a loading state while fetching data
    }

    return (
        <div>
            <section>
                <div className="cnsl7 cwkio cfd1l cwkz1">
                    <div className="c0ahj cm48u cef3hw c8d05">

                        <div className="ceiqu cwa15">

                            {/* Sidebar */}
                            <aside className="cu2gg crf2m ctnpq cfh68 cc7q4 cmvfi cy519 cjx7n">
                                <div className="c5uys ctbeu">

                                    <div className="czhnl c7z1d c2cn0 cwq9z cqwhl ck73f">

                                        <div className="czbam ccymi">

                                            <h2 className="cznhr clt06 cqdqx">Project Detail  </h2>
                                            <span> <Chip text="Sample" color={getChipColorFromStatus(jobData.status)} /> </span>
                                            <div className="ck6q6 cf37z c3774 cznhr">Min XP: <b>{jobData.minXP}</b></div>
                                        </div>

                                        <div className="cua4c c3tj1 cip9s c8og8">
                                            <ul className="cy6kr czodn ca7zr">
                                                <li className="cduop c8og8">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar">
                                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                                    </svg>
                                                    <span className="cm87k cf37z">&nbsp;  {formatFullDate(jobData.createdAt)} </span>
                                                </li>


                                                <li className="cduop c8og8" style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                    {/* Group 1 */}
                                                    <PriceIndicator price={getPriceRating(jobData.budget)} />

                                                    {/* Group 2 */}
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={point} alt="Points Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                                        <span style={{ fontSize: '14px', fontWeight: '600' }}>{jobData.points}</span>
                                                    </div>

                                                </li>



                                            </ul>
                                        </div>

                                        <div className="ct4vx cjjz8" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <button
                                                className="c40tu ce7qj c50eh c2fn4 cyky3 ctpla czwz3 c6kou"
                                                onClick={() => handleApply(jobID, jobData)}
                                            >
                                                Apply Now <span className="ce3ah ccolo cndda clfy9 comjk cmwpt c8bku">→</span>
                                            </button>
                                        </div>

                                        {/* <div className="czbam">
                                            <a className="c4von cw2fq c4vrg cf37z" href="#0">Visit Website</a>
                                        </div> */}

                                    </div>

                                </div>
                            </aside>

                            {/* Main content */}
                            <div className="cd11c">

                                {/* Project description */}
                                <div className="c8d05">
                                    <div className="cvxp4">

                                        <a className="cw2fq c4vrg cu1zo" onClick={() => navigate('/')} style={{ display: 'flex', alignItems: 'center' }}><span className="ccolo">←</span> &nbsp; All Jobs</a>
                                    </div>
                                    <h1 className="cmz24 cuad0 cgmpd c6cl6">{jobData.projectTitle}</h1>
                                    {/* Project description */}
                                    <div className="ctkqc cjx7n">
                                        <div>
                                            <h3 className="cznhr clt06 cevzo cd67x">About the Project</h3>
                                            <div className="ca6yp cb7bz">
                                                <p>{jobData.description}</p>

                                            </div>
                                        </div>
                                        <div>
                                            <h3 className="cznhr clt06 cevzo cd67x">Tech Requirements</h3>
                                            <div className="c0zhl">
                                                {jobData.techStack.map((tag, index) => ( // Use techStack
                                                    <a key={index} className="cfhya cy6kr ch6sm ceip1 cdxuw ce33e c7d26 c9eyc c4vrg ca6yp cww2f comjk cmwpt cv6oq" href="#0">
                                                        {tag}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                        <div>
                                            <h3 className="cznhr clt06 cevzo cd67x">Project Requirements</h3>
                                            <div className="ca6yp cb7bz">
                                                <p>{jobData.requirements}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <h3 className="cznhr clt06 cevzo cd67x">Key Responsibilities</h3>
                                            <div className="ca6yp cb7bz">
                                                <p>{jobData.responsibilities}</p>

                                            </div>
                                        </div>
                                    </div>
                                    {/* Social share */}
                                    <SocialShare shareLink={window.location.href} />
                                </div>

                                {/* Related jobs */}
                                {/* <div className="cjx7n">
                                    <h4 className="cuad0 clt06 cu7if cjx7n">Related Jobs</h4>
                                    
                                    <div className="czhnl ca7zr c9gvg c8og8">
                                        {jobData.map((job, index) => (
                                            <JobCard
                                                key={index}
                                                company={job.company}
                                                jobTitle={job.jobTitle}
                                                tags={job.tags}
                                                timePosted={job.timePosted}
                                                points={job.points}
                                                priceRating={job.priceRating}
                                                chipColor={job.chipColor}
                                                minPoints={job.minPoints}
                                            />
                                        ))}
                                    </div>
                                </div> */}

                                {/* Newletter CTA */}
                                <NewsletterCTA />

                            </div>

                        </div>

                    </div>
                </div>
            </section>

            <Modal
                show={showModal}
                onClose={handleCloseModal}
                heading={modalHeading}
                body={modalBody}
                showButtons={false}
            />

        </div>
    );
};

export default JobDetail;