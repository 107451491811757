import React, { useState } from 'react';
import { db } from '../../firebaseConfig/Firebase';
import { collection, doc, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';

const NewsletterCTA = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const docRef = doc(collection(db, "NotifyEmailList"), email);
            await setDoc(docRef, {
                userEmail: email,
                subscribedOn: new Date().getTime(),
                lastNotified: new Date().getTime(),
                doNotShowNotification: false,
            });
            toast.success("Email Registered Successfully");
            setEmail(''); // Clear the input field after submission
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    return (
        <div className="coscr cokkt czhnl coh2n">
            <div className="cwq9z cwkz1 c0qn4 czbam c6kou">
                <div
                    className="c6kdq cjxga c8f1b c3soh c7z1d cqwhl czhnl c2cn0 cww2f comjk cmwpt cjlhc"
                    aria-hidden="true"
                ></div>
                <div className="ck6q6 c1laq cevzo cw2fq"><b>Work on great Tech Projects</b></div>
                <div className="cip9s cu7if clt06">Get a weekly email with the Latest Project Posting.</div>
                <form className="cy6kr c0usk" onSubmit={handleSubmit}>
                    <div className="cfd1l c8og8 c1vmp ca7zr c3tj1 crk6p cti7q">
                        <input
                            type="email"
                            className="cule2 cmi0k cn582 c2fn4 ct5p9 c92ql"
                            placeholder="Your email"
                            aria-label="Your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button className="c40tu ch6sm ce7qj cyky3 ctpla czwz3" type="submit">
                            Notify Me
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NewsletterCTA;