import React from 'react';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Portfolio from '../components/Home/Portfolio';
import Hero from '../components/Home/Hero';
import TrustedBySection from '../components/Home/TrustedBy';
import HomeMain from '../components/Home/HomeMain';
import Testimonials from '../components/Home/Testimonial';


const Home = () => {
    return (
        <>
            <Header />
            <Hero />
            <TrustedBySection />
            <HomeMain />
            {/* <Intro />
            <Services /> */}
            {/* <Portfolio /> */}
            {/* <Clients />
            <Cta /> */}
            <Footer />
        </>

    )
}

export default Home;

