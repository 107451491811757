import React, { useEffect, useState } from 'react';
import JobCard from '../JobCard/JobCard';
import Testimonials from './Testimonial';
import NewsletterCTA from '../NewsletterCTA/NewsletterCTA';
import FilterGroup from './FilterGroup';
import { getFirestore, collection, getDocs, where, query, onSnapshot } from 'firebase/firestore';
import { getPriceRating } from '../../utils/otherFunctions';
import { getChipColorFromStatus } from '../../utils/otherFunctions';
import { skillOptions, projectRoleTypeData } from '../../data/data';

const HomeMain = () => {
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [filters, setFilters] = useState({
        projectStatus: [],
        projectRoles: [],
        techSkills: [],
        projectBudget: [],
        minXP: [],
        isPaidOnly: false
    });


    const matchesBudgetFilter = (budget, filterValue) => {
        switch (filterValue) {
            case "FREE":
                return budget < 1;
            case "$":
                return budget > 0 && budget <= 10000;
            case "$$":
                return budget > 10000 && budget <= 50000;
            case "$$$":
                return budget > 50000 && budget <= 75000;
            case "$$$$":
                return budget > 75000;
            default:
                return true;
        }
    };



    const matchesXPFilter = (xp, filterValue) => {
        switch (filterValue) {
            case "0":
                return xp === 0;
            case "1 - 10":
                return xp > 0 && xp <= 10;
            case "10 - 50":
                return xp > 10 && xp <= 50;
            case "50 - 100":
                return xp > 50 && xp <= 100;
            case "100 - 250":
                return xp > 100 && xp <= 250;
            case "250 - 500":
                return xp > 250 && xp <= 500;
            case "500 - 1000":
                return xp > 500 && xp <= 1000;
            case "1000+":
                return xp > 1000;
            default:
                return true;
        }
    };



    const applyFilters = () => {
        let filtered = [...jobs];

        // Project Status Filter
        if (filters.projectStatus.length > 0) {
            filtered = filtered.filter(job =>
                (filters.projectStatus.includes("Ongoing") && job.status === "Ongoing") ||
                (filters.projectStatus.includes("Open") && job.status === "Pending")
            );
        }


        // Project Roles Filter (using projectType field)
        if (filters.projectRoles.length > 0) {
            filtered = filtered.filter(job =>
                filters.projectRoles.includes(job.projectType)
            );
        }

        // Tech Skills Filter (using techStack field)
        if (filters.techSkills.length > 0) {
            filtered = filtered.filter(job =>
                job.tags.some(tag => filters.techSkills.includes(tag))
            );
        }

        // Project Budget Filter
        if (filters.projectBudget.length > 0) {
            filtered = filtered.filter(job =>
                filters.projectBudget.some(budget => matchesBudgetFilter(job.budget, budget))
            );
        }

        // Min XP Filter
        if (filters.minXP.length > 0) {
            filtered = filtered.filter(job =>
                filters.minXP.some(xp => matchesXPFilter(job.minPoints, xp))
            );
        }

        // Paid Only Filter
        if (filters.isPaidOnly) {
            filtered = filtered.filter(job => job.budget > 0);
        }

        setFilteredJobs(filtered);
    };







    useEffect(() => {
        const db = getFirestore();
        const projectsRef = collection(db, "Projects");

        const unsubscribe = onSnapshot(
            query(projectsRef, where("status", "in", ["Pending", "Ongoing"])),
            (querySnapshot) => {
                const jobsData = querySnapshot.docs
                    .map((doc) => ({
                        jobID: doc.id,
                        jobTitle: doc.data().projectTitle,
                        tags: doc.data().techStack,
                        points: doc.data().points,
                        minPoints: doc.data().minXP,
                        isPromoted: doc.data().isStickPostonTop > 0,
                        chipColor: getChipColorFromStatus(doc.data().status),
                        priceRating: getPriceRating(doc.data().budget),
                        isStickPostonTop: doc.data().isStickPostonTop,
                        status: doc.data().status,
                        budget: doc.data().budget,
                        projectType: doc.data().projectType,
                    }));

                jobsData.sort((a, b) => b.isStickPostonTop - a.isStickPostonTop);
                setJobs(jobsData);
                setFilteredJobs(jobsData);
            }
        );

        return () => unsubscribe();
    }, []);

    // Effect to apply filters whenever they change
    useEffect(() => {
        applyFilters();
    }, [filters, jobs]);

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };

    return (
        <section>
            <div className="cfd1l cnsl7 cwkz1 cwkio">
                <div className="coh2n cm5ip">
                    <div className="cwa15 ceiqu">
                        {/* Sidebar */}
                        <aside className="cjx7n crf2m cc7q4 ctnpq cmvfi cu2gg cfh68 cy519">
                            <div className="c5uys ctbeu">
                                <div className="cwq9z c7z1d cqwhl czhnl c2cn0 ck73f">
                                    <div className="c8dcm c3ma2 cx1zb cr8k3">
                                        <FilterGroup
                                            filters={filters}
                                            onFilterChange={handleFilterChange}

                                        />
                                        {/* Solo Projects Toggle */}

                                    </div>
                                </div>
                            </div>
                        </aside>

                        {/* Main content */}
                        <div className="cd11c">
                            <div className="c8d05 c0ahj">
                                <h2 className="c6cl6 cuad0 cfibj clt06">Projects</h2>
                                <div className="c8og8 ca7zr">
                                    <NewsletterCTA />
                                    <div>
                                        {filteredJobs.length > 0 ? (
                                            filteredJobs.map((job, index) => (
                                                <JobCard
                                                    key={index}
                                                    {...job}
                                                />
                                            ))
                                        ) : (
                                            <h2 className="c6cl6 cuad0 cfibj clt06 cwtaw">No Project</h2>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Testimonials />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeMain;