import React from 'react';
import { handleShareToSocials } from '../../utils/otherFunctions';

const SocialShare = ({ shareLink }) => {
    return (
        <div className="cduop cemyy cu4k7 c8og8">
            <div className="ccyd8 c1laq cevzo">Share job</div>
            <ul className="cy6kr c40vg">
                <li>
                    <a className="c16qw cve8r cw2fq c3tj1 ct79c cduop c1g5q comjk cmwpt cww2f c8og8" href="#0" aria-label="Twitter" onClick={() => handleShareToSocials('twitter', shareLink)}>
                        <svg className="ca4xu c4lqp c19rb" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="m13.063 9 3.495 4.475L20.601 9h2.454l-5.359 5.931L24 23h-4.938l-3.866-4.893L10.771 23H8.316l5.735-6.342L8 9h5.063Zm-.74 1.347h-1.457l8.875 11.232h1.36l-8.778-11.232Z"></path>
                        </svg>
                    </a>
                </li>
                <li>
                    <a className="c16qw cve8r cw2fq c3tj1 ct79c cduop c1g5q comjk cmwpt cww2f c8og8" href="#0" aria-label="Facebook" onClick={() => handleShareToSocials('facebook', shareLink)}>
                        <svg className="ca4xu c4lqp c19rb" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.023 24 14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257Z"></path>
                        </svg>
                    </a>
                </li>
                <li>
                    <a className="c16qw cve8r cw2fq c3tj1 ct79c cduop c1g5q comjk cmwpt cww2f c8og8" href="#0" aria-label="Telegram" onClick={() => handleShareToSocials('telegram', shareLink)}>
                        <svg className="ca4xu c4lqp c19rb" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.968 10.276a.338.338 0 0 0-.232-.253 1.192 1.192 0 0 0-.63.045s-14.019 5.038-14.82 5.596c-.172.121-.23.19-.259.272-.138.4.293.573.293.573l3.613 1.177a.388.388 0 0 0 .183-.011c.822-.519 8.27-5.222 8.7-5.38.068-.02.118 0 .1.049-.172.6-6.606 6.319-6.64 6.354a.138.138 0 0 0-.05.118l-.337 3.528s-.142 1.1.956 0a30.66 30.66 0 0 1 1.9-1.738c1.242.858 2.58 1.806 3.156 2.3a1 1 0 0 0 .732.283.825.825 0 0 0 .7-.622s2.561-10.275 2.646-11.658c.008-.135.021-.217.021-.317a1.177 1.177 0 0 0-.032-.316Z"></path>
                        </svg>
                    </a>
                </li>
                {/* <li>
                    <a className="c16qw cve8r cw2fq c3tj1 ct79c cduop c1g5q comjk cmwpt cww2f c8og8" href="#0" aria-label="Instagram" onClick={() => handleShareToSocials('instagram', shareLink)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram ca4xu c4lqp c19rb" viewBox="-4 -4 24 24">
                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                        </svg>
                    </a>
                </li> */}
            </ul>
        </div>
    );
};

export default SocialShare;