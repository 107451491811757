import React, { useState, useEffect } from 'react';
import NotificationCard from '../components/NotificationCard/NotificationCard';
import { useNavigate } from 'react-router-dom';


import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig/Firebase';



const Notification = () => {

  const PageTags = ['Typescript', 'CSS', 'HTML', 'React', 'Node.js', 'Next.js', 'Tailwind']

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Fetch user data from Firestore
        console.log("User is signed in")
      } else {
        // Redirect to login page if user is not authenticated
        navigate('/signin');
      }
    });

    // Clean up the subscription
    return () => unsubscribe();
  }, [auth, navigate]);

  return (
    <div>
      <div className="main-content">
        {/* Header */}
        <div className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center" style={{ maxHeight: '100px', backgroundImage: 'url(https://img.freepik.com/free-vector/minimal-diagonal-zigzag-pattern-design_1017-15351.jpg)', backgroundSize: 'cover', backgroundPosition: 'center top' }}>
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8"></span>
          {/* Header container */}
          <div className="container-fluid d-flex align-items-center">
            <div className="row">
              <div className="col-lg-7 col-md-10">
                <h1 className="display-2 text-white">Notifications</h1>
              </div>
            </div>
          </div>
        </div>
        {/* Page content */}
        <div className="container-fluid mt--7">
          <div className="row">
            <div className="col-xl-12 order-xl-1">
              <div className="card bg-secondary shadow">
                <div className="card-body">
                  <div className="pl-lg-4 pr-lg-4 mb--5">
                    <NotificationCard />
                    <NotificationCard />
                    <NotificationCard />
                    <NotificationCard />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>

        {`

                :root {
  --blue: #5e72e4;
  --indigo: #5603ad;
  --purple: #8965e0;
  --pink: #f3a4b5;
  --red: #f5365c;
  --orange: #fb6340;
  --yellow: #ffd600;
  --green: #2dce89;
  --teal: #11cdef;
  --cyan: #2bffc6;
  --white: #fff;
  --gray: #8898aa;
  --gray-dark: #32325d;
  --light: #ced4da;
  --lighter: #e9ecef;
  --primary: #5e72e4;
  --secondary: #f7fafc;
  --success: #2dce89;
  --info: #11cdef;
  --warning: #fb6340;
  --danger: #f5365c;
  --light: #adb5bd;
  --dark: #212529;
  --default: #172b4d;
  --white: #fff;
  --neutral: #fff;
  --darker: black;
}

.main-content {
  position: relative;
}

.header {
  position: relative;
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.header {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-gradient-default {
  background: linear-gradient(87deg, #6366f1 0, #6366f1 50%, #6366f1 50%, #6a11ef 100%) !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
}

.col-lg-7 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .col-lg-7 {
    max-width: 58.33333%;
    flex: 0 0 58.33333%;
  }
}

.display-2 {
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 1.5;
}

.text-white {
  color: #fff !important;
}

.mt--12 {
  margin-top: -8rem !important;
}

.mb--5 {
  margin-top: 5rem !important;
}

.bg-secondary {
  background-color: #f7fafc !important;
}

.shadow {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  background-color: #fff;
  background-clip: border-box;
}

.col-xl-8 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1200px) {
  .col-xl-8 {
    max-width: 66.66667%;
    flex: 0 0 66.66667%;
  }
}

.order-xl-1 {
  order: 1;
}

                `}

      </style>
    </div>

  );
};

export default Notification;