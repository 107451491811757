import testimonialAuth01 from '../images/signin/testimonial-auth-01.jpg';
import testimonialAuth02 from '../images/signin/testimonial-auth-02.jpg';
import testimonialAuth03 from '../images/signin/testimonial-auth-03.jpg';
import testimonial04 from '../images/list-job/testimonial-auth-04.jpg';
import testimonial05 from '../images/list-job/testimonial-auth-05.jpg';
import testimonial06 from '../images/list-job/testimonial-auth-06.jpg';
import company01 from "../images/home/company-icon-01.svg";
import company02 from "../images/home/company-icon-02.svg";

export const sideTestimonialSigninData = [
    {
        image: testimonialAuth01,
        quote: "This platform gave me the chance to work on real-world tech projects while still in college. I learned so much and even earned some money along the way!",
        name: "Rohan Sharma",
        designation: "student",
        institution: "IIT Bombay"
    },
    {
        image: testimonialAuth02,
        quote: "This platform has been a game-changer. I've gained valuable experience, expanded my skill set, and worked on exciting projects.",
        name: "Priya Desai",
        designation: "student",
        institution: "SRM Chennai"
    },
    {
        image: testimonialAuth03,
        quote: "I joined to upskill myself, and the experience has been incredible. The projects challenged me, helped me learn new technologies, and even brought in some extra income.",
        name: "Lisa Smith",
        designation: "passout",
        institution: "Thappar University"
    }
];


export const sideTestimonialListJobData = [
    {
        image: testimonial04,
        quote: "The rates on this platform were significantly lower than hiring freelancers or agencies. We got quality work done at a fraction of the cost!",
        name: "Aravind Singh",
        designation: "CEO",
        institution: "Skillit"
    },
    {
        image: testimonial05,
        quote: "The platform was incredibly reliable and responsive. They ensured our project was done perfectly, with constant updates and attention to detail.",
        name: "Nikhil Nair",
        designation: "Project Manager",
        institution: "Capexgo"
    },
    {
        image: testimonial06,
        quote: "We had a hassle-free experience with this platform managing the entire project coordination. Everything was handled efficiently, so we didn't have to worry about a thing.",
        name: "Abhishek Sharma",
        designation: "Founder",
        institution: "Nemesis Media"
    }
];

export const genderOptions = ['Male', 'Female', 'Non Binary'];

export const maleAvatars = [
    "https://firebasestorage.googleapis.com/v0/b/project-mela.appspot.com/o/Avatars%2Favatar-male-1.png?alt=media&token=5812643f-910f-417a-a064-bd2c60f012d0",
    "https://firebasestorage.googleapis.com/v0/b/project-mela.appspot.com/o/Avatars%2Favatar-male-2.png?alt=media&token=15736a34-6315-4ab3-840c-22b3fe8f5fbd",
    "https://firebasestorage.googleapis.com/v0/b/project-mela.appspot.com/o/Avatars%2Favatar-male-3.png?alt=media&token=7db39ae0-e3c0-4751-a067-25f85ee75ff4",
    "https://firebasestorage.googleapis.com/v0/b/project-mela.appspot.com/o/Avatars%2Favatar-male-4.png?alt=media&token=9bac85bb-8d5c-4ce3-958f-2eeb7356eb77",
    "https://firebasestorage.googleapis.com/v0/b/project-mela.appspot.com/o/Avatars%2Favatar-male-5.png?alt=media&token=dabeed83-9971-44de-9d37-42e0fc5360f7"
];

export const femaleAvatars = [
    "https://firebasestorage.googleapis.com/v0/b/project-mela.appspot.com/o/Avatars%2Favatar-female-1.png?alt=media&token=a3a69ed8-b522-473f-a2a2-903b5a6b4b5c",
    "https://firebasestorage.googleapis.com/v0/b/project-mela.appspot.com/o/Avatars%2Favatar-female-2.png?alt=media&token=0905e617-999d-46a1-8e3e-f215dc23c2ad",
    "https://firebasestorage.googleapis.com/v0/b/project-mela.appspot.com/o/Avatars%2Favatar-female-3.png?alt=media&token=d0855090-43bd-40ba-8477-cfb117c5aa4e",
    "https://firebasestorage.googleapis.com/v0/b/project-mela.appspot.com/o/Avatars%2Favatar-female-4.png?alt=media&token=5742d33a-f136-44b2-b8b1-0d0f5841d62a"
];

export const skillOptions = [
    'Agile Methodologies', 'AI/ML', 'AJAX', 'Algorithm Design', 'AWS', 'Amazon DynamoDB', 'Amazon Redshift', 'Amazon S3',
    'Android Development', 'Angular', 'Ansible', 'API Development', 'App Development', 'ASP.NET', 'Assembly Language', 'Azure',
    'Back-End Development', 'Big Data', 'Blazor', 'Blockchain', 'Bootstrap', 'C', 'C#', 'C++', 'CakePHP', 'Cloud Architecture',
    'Cloud Computing', 'Cloud Infrastructure', 'Clojure', 'CMake', 'Computer Networking', 'Containerization', 'Continuous Deployment',
    'Continuous Integration', 'Cordova', 'CSS', 'Cybersecurity', 'D3.js', 'Data Analysis', 'Data Engineering', 'Data Mining', 'Data Science',
    'Dart', 'Database Design', 'Django', 'Docker', 'Drupal', 'ECMAScript', 'Elasticsearch', 'Electron', 'Elixir', 'Ember.js',
    'Entity Framework', 'Ethereum', 'Express.js', 'F#', 'Firebase', 'Flask', 'Flutter', 'Fortran', 'Front-End Development',
    'Full-Stack Development', 'Game Development', 'Gatsby.js', 'Git', 'GitHub', 'GitLab', 'Go', 'Google Cloud Platform', 'GraphQL',
    'Groovy', 'gRPC', 'Hadoop', 'Haskell', 'HTML', 'IaaS', 'Ionic', 'iOS Development', 'Java', 'JavaScript', 'Jenkins', 'Jest', 'Jira',
    'Joomla', 'jQuery', 'JSON', 'Julia', 'Kotlin', 'Kubernetes', 'Laravel', 'Linux', 'Load Balancing', 'Lua', 'Machine Learning',
    'MariaDB', 'MATLAB', 'Memcached', 'Microservices', 'Mobile App Development', 'MongoDB', 'MVC Architecture', 'MySQL',
    'Natural Language Processing', 'Neural Networks', 'Next.js', 'NGINX', 'Node.js', 'NoSQL', 'NumPy', 'Objective-C', 'OAuth',
    'OpenCV', 'OpenGL', 'OpenStack', 'Oracle', 'PaaS', 'Pandas', 'Perl', 'PHP', 'PostgreSQL', 'Progressive Web Apps', 'Puppet',
    'Python', 'R', 'RabbitMQ', 'React', 'React Native', 'Redis', 'Redux', 'REST APIs', 'Ruby', 'Ruby on Rails', 'Rust', 'SaaS',
    'Sass', 'Scala', 'SCSS', 'SEO', 'Shell Scripting', 'Socket.io', 'Software Architecture', 'Software Development', 'Solidity',
    'Spring Boot', 'SQL', 'SQLite', 'SSL/TLS', 'Swift', 'Symfony', 'System Design', 'TDD (Test-Driven Development)', 'TensorFlow',
    'Terraform', 'Three.js', 'TypeScript', 'UI/UX Design', 'Unit Testing', 'Unity', 'Unreal Engine', 'Vagrant', 'VB.NET', 'Version Control',
    'Virtualization', 'Visual Basic', 'Vue.js', 'Web Development', 'WebRTC', 'WebSockets', 'Webpack', 'Windows Server', 'WordPress',
    'Xamarin', 'XML', 'YAML', 'Yii', 'Zig', 'Zookeeper', 'Zoom API', 'Zurb Foundation'
];

export const projectRoleTypeData = [
    "Full Scale Project",
    "Short Term Project",
    "Internship",
    "Building MVP",
    "Tasks",
    "Updates / Modifications",
    "Fixing Bugs and Issues",
    "Continuous Work",


]

export const maxLengthChecksforProfile = {
    'FirstName': 100,
    'LastName': 100,
    'Email': 100,
    'Mobile': 100,
    'ResumeLink': 300,
    'GithubLink': 300,
    'LinkedinLink': 300,
    'InstagramLink': 300,
    'Designation': 100,
    'AboutMe': 2000,
    'MyExperience': 2000,
    'PastProjects': 2000
};

export const maxLengthChecksForListjob = {
    'name': 60,
    'email': 60,
    'projectTitle': 200,
    'description': 2000,
    'requirements': 2000,
    'responsibilities': 2000
};

export const adminsCredential = [
    { id: '1234', password: '1234' },
    { id: '88226934', password: 'fI6hkRuXIj' },
    { id: '83001513', password: '3cmvu9VBHF' },
    { id: '89278566', password: 'HfIvO3BcSH' },
    { id: '85036902', password: 'iLnnOPgvjn' }
];

export const SUPER_ADMIN_ID = "5678";


export const dummyDataRows = [
    {
        projectName: 'Building an Ecommerce Store on React',
        projectLink: '#',
        applicantNames: ['Sneha Gupta'],
        applicantLinks: ['#', '#', '#', '#', '#'],
        skills: ['React', 'Node', 'Express', 'MongoDB', 'Stripe'],
        date: '15 Aug, 2024',
        points: '120',
        status: 'Pending',
        statusClass: 'bg-warning',
        clientName: 'John Doe',
        clientLink: '#',
        moderatorNames: ['Max Fosh', 'David Liu', 'Sarah Thompson', 'Emily White', 'Chris Evans'],
        moderatorLinks: ['#', '#', '#', '#', '#'],
        notifyUser: false,
        budget: "$5000",

    },
    {
        projectName: 'Creating a Chatbot with Natural Language Processing',
        projectLink: '#',
        applicantNames: ['Vinay Rao'],
        applicantLinks: ['#', '#', '#', '#', '#'],
        skills: ['Python', 'NLTK', 'TensorFlow', 'Machine Learning'],
        date: '20 Aug, 2024',
        points: '100',
        status: 'Pending',
        statusClass: 'bg-warning',
        clientName: 'Jane Smith',
        clientLink: '#',
        moderatorNames: ['MLinda Carter', 'George Wilson', 'Harper Brown', 'Noah Scott', 'Mia Roberts'],
        moderatorLinks: ['#', '#', '#', '#', '#'],
        notifyUser: false,
        budget: "$5000",

    },
];


export const jobData = [
    {
        company: { name: 'Company Name', logo: company02 },
        jobTitle: 'Building an Ecommerce Store using React and Firebase',
        tags: ['Web Developement', 'Raect', 'Node', 'Firebase', 'Tailwind', 'SASS', 'TypeScript'],
        points: 100,
        priceRating: 2,
        chipColor: 'orange',
        minPoints: 50,
        isPromoted: 10,

    },
    // Add more job objects as needed
    {
        company: { name: 'Another Company', logo: company02 },
        jobTitle: 'Building a Health and Fitness Related App on Flutter',
        tags: ['App Developement', 'Flutter', 'Firebase', 'Android Studios'],
        points: 200,
        priceRating: 3,
        chipColor: 'green',
        minPoints: 100,
        isPromoted: 0,

    },

    {
        company: { name: 'Another Company', logo: company02 },
        jobTitle: 'Integration of Payment Gateway in React Website',
        tags: ['API Integration', 'Payment Gateway', 'Backend Developer', 'React', 'Node', 'Express'],
        points: 50,
        priceRating: 1,
        chipColor: 'orange',
        minPoints: 100,
        isPromoted: 0,

    },
];















