//General Imports
import React, { useState } from 'react';

//Frontend Imports
import { FaTimes } from 'react-icons/fa';
import "../../CSS/AdminDashboard.css";
import "../../pages/Home.css";
import "./TableRow.css";
import Modal from '../General/Modal/Modal';
import { toast } from 'react-toastify';
import ScrollableModal from '../General/ScrollableModal/ScrollableModal';

//Backend Imports 
import { doc, updateDoc, getDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { db } from "../../firebaseConfig/Firebase";


// Data Imports
import { skillOptions } from '../../data/data';
import { SUPER_ADMIN_ID } from '../../data/data';

//Function Import
import { handleApplicantProfileClick } from '../../utils/adminDashboardUtils';



const MAX_SKILLS_PER_ROW = 4;
const MAX_APPLICANTS_PER_ROW = 4;
const MAX_MODERATORS_PER_ROW = 4;

const TableRow = ({
    projectId,
    projectName,
    onProjectClick = () => { },
    removedApplicantNames,
    onRemovedApplicantClick = () => { },
    applicantNames,
    onApplicantClick = () => { },
    clientName,
    onClientClick = () => { },
    moderatorNames,
    onModeratorClick = () => { },
    removedModerators,
    skills,
    date,
    points,
    minXP,
    status,
    statusClass,
    budget,
    notifyUser,
    activeTab,
    onMainButtonClick,
    onDeleteClick,

}) => {

    const [showModal, setShowModal] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [modalHeading, setModalHeading] = useState('');
    const [userModalHeading, setUserModalHeading] = useState('');
    const [userModalMessage, setUserModalMessage] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showModeratorModal, setShowModeratorModal] = useState(false);
    const [newModeratorEmail, setNewModeratorEmail] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [editingField, setEditingField] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedSkills, setSelectedSkills] = useState(skills);
    const [searchTerm, setSearchTerm] = useState('');
    const [superAdminID, setSuperAdminID] = useState('');

    const [currentUserEmail, setCurrentUserEmail] = useState('');
    const [isRemovedUser, setIsRemovedUser] = useState(false);
    const [userType, setUserType] = useState('');



    const handleEditClick = (field) => {
        if (activeTab === "Applications" && field === 'points') return;

        setEditingField(field);
        setModalHeading(field === 'budget' ? 'Edit Budget' : field === 'points' ? 'Edit Points' : 'Edit Min XP');
        setInputValue(field === 'budget' ? budget : field === 'points' ? points : minXP);
        setShowModal(true);
    };



    const handleSkillAddClick = () => {
        setShowDropdown(!showDropdown);
        setEditingField('skills');
    };

    const handleRemoveSkill = (skillToRemove) => {
        setSelectedSkills(prevSkills => prevSkills.filter(skill => skill !== skillToRemove));
    };

    const handleSkillChange = (skill, e) => {
        // Stop event propagation to prevent double triggering
        if (e) {
            e.stopPropagation();
        }

        setSelectedSkills(prevSkills => {
            if (prevSkills.includes(skill)) {
                return prevSkills.filter(s => s !== skill);
            } else if (prevSkills.length >= 20) {
                toast.error("You cannot select more than 20 skills.");
                return prevSkills;
            } else {
                return [...prevSkills, skill];
            }
        });
    };


    const filteredSkills = skillOptions.filter(skill =>
        skill.toLowerCase().includes(searchTerm.toLowerCase())
    );



    const handleSkillSave = async () => {
        try {
            const docRef = doc(db, "Projects", projectId);
            await updateDoc(docRef, {
                techStack: selectedSkills
            });
            setShowDropdown(false);
            toast.success('Skills updated successfully!');
        } catch (error) {
            console.error("Error updating document: ", error);
            toast.error('Error updating skills');
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };


    const handleModeratorAdd = async () => {

        if (!newModeratorEmail.trim()) {
            toast.error('Please enter a moderator email');
            return;
        }

        if (!isValidEmail(newModeratorEmail)) {
            toast.error('Please enter a valid email address');
            return;
        }

        try {
            const docRef = doc(db, "Projects", projectId);

            // Get the current document to access existing moderators
            const projectDoc = await getDoc(docRef);
            const currentModerators = projectDoc.data().moderators || [];

            // Check if moderator already exists
            if (currentModerators.includes(newModeratorEmail.trim())) {
                toast.error('This moderator is already added');
                return;
            }

            // Add the new moderator email to the array
            await updateDoc(docRef, {
                moderators: [...currentModerators, newModeratorEmail.trim()]
            });

            setShowModeratorModal(false);
            setNewModeratorEmail('');
            setSuperAdminID('');
            toast.success('Moderator added successfully!');
        } catch (error) {
            console.error("Error adding moderator: ", error);
            toast.error('Error adding moderator');
        }
    };


    const handleUserActionClick = async () => {
        try {
            const docRef = doc(db, "Projects", projectId);

            if (isRemovedUser) {
                // Handle adding back removed users
                if (userType === 'applicant') {
                    await updateDoc(docRef, {
                        selectedApplicants: arrayUnion(currentUserEmail),
                        removedApplicants: arrayRemove(currentUserEmail)
                    });
                    toast.success('Applicant added back successfully!');
                } else if (userType === 'moderator') {
                    await updateDoc(docRef, {
                        moderators: arrayUnion(currentUserEmail),
                        removedModerators: arrayRemove(currentUserEmail)
                    });
                    toast.success('Moderator added back successfully!');
                }
            } else {
                // Handle removing users
                if (userType === 'applicant') {
                    await updateDoc(docRef, {
                        selectedApplicants: arrayRemove(currentUserEmail),
                        removedApplicants: arrayUnion(currentUserEmail)
                    });
                    toast.success('Applicant removed successfully!');
                } else if (userType === 'moderator') {
                    await updateDoc(docRef, {
                        moderators: arrayRemove(currentUserEmail),
                        removedModerators: arrayUnion(currentUserEmail)
                    });
                    toast.success('Moderator removed successfully!');
                }
            }
            setShowUserModal(false);
        } catch (error) {
            console.error("Error updating document: ", error);
            toast.error('Error updating user status');
        }
    };

    // Modify the handleApplicantProfileClick call
    const handleUserClick = (email, isRemovedUser, userType) => {
        setCurrentUserEmail(email);
        setIsRemovedUser(isRemovedUser);
        setUserType(userType);
        handleApplicantProfileClick(
            email,
            setUserModalHeading,
            setUserModalMessage,
            setShowUserModal,
            false
        );
    };


    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSave = async () => {


        try {

            const docRef = doc(db, "Projects", projectId);

            if (editingField === 'budget') {
                await updateDoc(docRef, {
                    budget: parseFloat(inputValue)
                });
            } else if (editingField === 'points') {
                await updateDoc(docRef, {
                    points: inputValue
                });
            } else if (editingField === 'minXP') { // Add minXP update logic
                await updateDoc(docRef, {
                    minXP: inputValue
                });
            }
            setShowModal(false);
            toast.success('Field updated successfully!');
        } catch (error) {
            console.error("Error updating document: ", error);
            toast.error('Error updating field');
        }
    };


    const handleDeleteClick = () => {
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const docRef = doc(db, "Projects", projectId);
            await updateDoc(docRef, {

                isDeletedByAdmin: true
            });
            setShowDeleteModal(false);
            toast.success('Document deleted successfully!');
            if (onDeleteClick) {
                onDeleteClick();
            }
        } catch (error) {
            console.error("Error updating document: ", error);
            toast.error('Error marking document as deleted');
        }
    };

    const handleApplicationDeletion = async () => {
        try {
            const docRef = doc(db, "Applications", projectId);
            await updateDoc(docRef, {
                isDeleted: true
            });
            setShowDeleteModal(false);
            toast.success('Application deleted successfully!');
            if (onDeleteClick) {
                onDeleteClick();
            }
        } catch (error) {
            console.error("Error deleting application: ", error);
            toast.error('Error deleting application');
        }
    };


    const getButtonText = () => {
        switch (activeTab) {
            case "Applications":
                return "Accept";
            case "Pending":
                return "Start";
            case "Ongoing":
                return "Close";
            case "Project Requests":
                return "Approve";
            default:
                return "Default";
        }
    };

    return (
        <>
            <tr>
                <td>

                    <a className="text-heading font-semibold cu1zo" onClick={onProjectClick}>
                        {projectName}
                    </a>
                </td>

                <td>
                    <a className="text-heading font-semibold cu1zo" onClick={onClientClick}>
                        {clientName}
                    </a>
                </td>


                {/* Display multiple Moderators */}

                {activeTab === "Pending" || activeTab === "Ongoing" ? (
                    <td>


                        {/* Removed Moderators */}

                        {(activeTab === "Pending" || activeTab === "Ongoing") && (
                            <div style={{ maxWidth: '400px', overflowWrap: 'break-word', marginRight: '20px' }}>
                                {removedModerators.map((moderator, index) => (
                                    <React.Fragment key={index}>
                                        <a className="text-heading font-semibold red-text cu1zo" onClick={() => handleUserClick(moderator, true, 'moderator')}>
                                            {moderator}
                                        </a>
                                        {index % MAX_APPLICANTS_PER_ROW === MAX_APPLICANTS_PER_ROW - 1 && <br />}
                                        {index < removedModerators.length - 1 && '  ||  '}
                                    </React.Fragment>
                                ))}
                            </div>
                        )}

                        <div style={{ maxWidth: '400px', overflowWrap: 'break-word', marginRight: '20px' }}>
                            {moderatorNames.map((moderator, index) => (
                                <React.Fragment key={index}>
                                    <a className="text-heading font-semibold cu1zo" onClick={() => handleUserClick(moderator, false, 'moderator')}>
                                        {moderator}
                                    </a>
                                    {index % MAX_MODERATORS_PER_ROW === MAX_MODERATORS_PER_ROW - 1 && <br />}
                                    {index < moderatorNames.length - 1 && '  ||  '} {/* Separator */}




                                </React.Fragment>

                            ))}

                        </div>

                        <button type="button" className="btn btn-sm btn-neutral d-flex justify-content-center align-items-center" onClick={() => setShowModeratorModal(true)} style={{ maxWidth: '30px', maxHeight: '30px' }}>
                            <b>+</b>
                        </button>
                    </td>
                ) : null}



                {/* Display multiple applicants */}

                {activeTab === "Pending" || activeTab === "Ongoing" || activeTab === "Applications" ? (
                    <td>
                        {/* Removed Applicants */}

                        {(activeTab === "Pending" || activeTab === "Ongoing") && (
                            <div style={{ maxWidth: '400px', overflowWrap: 'break-word', marginRight: '20px' }}>
                                {removedApplicantNames.map((applicant, index) => (
                                    <React.Fragment key={index}>
                                        <a className="text-heading font-semibold red-text cu1zo" onClick={() => handleUserClick(applicant, true, 'applicant')}>
                                            {applicant}
                                        </a>
                                        {index % MAX_APPLICANTS_PER_ROW === MAX_APPLICANTS_PER_ROW - 1 && <br />}
                                        {index < removedApplicantNames.length - 1 && '  ||  '}
                                    </React.Fragment>
                                ))}
                            </div>
                        )}


                        {/* Selected Applicants */}

                        <div style={{ maxWidth: '400px', overflowWrap: 'break-word', marginRight: '20px' }}>
                            {applicantNames.map((applicant, index) => (
                                <React.Fragment key={index}>
                                    <a className="text-heading font-semibold cu1zo" onClick={() => {
                                        if (activeTab === "Pending" || activeTab === "Ongoing") {
                                            handleUserClick(applicant, false, 'applicant')
                                        }
                                        if (activeTab === "Applications") {
                                            handleApplicantProfileClick(projectId, setUserModalHeading, setUserModalMessage, setShowUserModal, true);
                                        }
                                    }}>
                                        {applicant}
                                    </a>
                                    {index % MAX_APPLICANTS_PER_ROW === MAX_APPLICANTS_PER_ROW - 1 && <br />}
                                    {index < applicantNames.length - 1 && '  ||  '} {/* Separator */}
                                </React.Fragment>
                            ))}
                        </div>
                    </td>
                ) : null}

                <td>
                    {date}
                </td>


                {activeTab === "Project Requests" || activeTab === "Ongoing" || activeTab === "Pending" ? (
                    <td>
                        {budget === -1 ? (
                            <span className="text-heading font-semibold cu1zo red-text" onClick={() => handleEditClick('budget')}>N/A</span>
                        ) : (
                            <a className="text-heading font-semibold cu1zo" onClick={() => handleEditClick('budget')}>
                                {budget}
                            </a>
                        )}
                    </td>
                ) : null}


                <td>

                    <div className="c0zhl">


                        {showDropdown ? (

                            <div className="tech-stack-dropdown" style={{ maxHeight: '300px', overflowY: 'auto', position: 'relative' }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', position: 'sticky', top: -17, zIndex: 1, backgroundColor: 'white' }}>
                                    <div className="search-bar-container">
                                        <input
                                            type="text"
                                            placeholder="Search skills..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            style={{ width: '100%', padding: '10px', margin: '10px 0', border: '1px solid #ccc', borderRadius: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                                        />
                                    </div>

                                    <span style={{ display: 'flex', justifyContent: 'left', }}>
                                        <button className="cf37z c4vrg cw2fq c4von" onClick={handleSkillSave}>Save</button>
                                        <span className="cfhya cy6kr ch6sm ceip1 cdxuw ce33e c7d26 c9eyc c4vrg ca6yp cww2f comjk cmwpt cv6oq cu1zo " onClick={() => setShowDropdown(false)}> <FaTimes size={12} /> </span>
                                    </span>

                                </div>
                                {filteredSkills.map(option => (
                                    <div key={option}>
                                        <input
                                            type="checkbox"
                                            id={option}
                                            className="cicja"
                                            checked={selectedSkills.includes(option)}
                                            onChange={(e) => handleSkillChange(option, e)}
                                        />
                                        <label htmlFor={option} className="cvhuf cf37z cm87k">{option}</label>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="selected-tech-stack">
                                {selectedSkills.map((tag, index) => (
                                    <React.Fragment key={index}>

                                        <span className="cfhya cy6kr ch6sm ceip1 cdxuw ce33e c7d26 c9eyc c4vrg ca6yp cww2f comjk cmwpt cv6oq" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                            {tag}
                                            {activeTab !== "Applications" && (
                                                <button
                                                    onClick={() => handleRemoveSkill(tag)}
                                                    style={{ marginLeft: '5px', background: 'none', border: 'none', cursor: 'pointer', padding: '0', display: 'flex', alignItems: 'center' }}
                                                >
                                                    <FaTimes size={12} />
                                                </button>
                                            )}
                                        </span>
                                        {index % MAX_SKILLS_PER_ROW === MAX_SKILLS_PER_ROW - 1 && <br />}
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                    </div>

                    {(activeTab === "Pending" || activeTab === "Ongoing" || activeTab === "Project Requests") && (
                        <button type="button" className="btn btn-sm btn-neutral" onClick={handleSkillAddClick}>
                            <b>+</b>
                        </button>
                    )}



                </td>


                {(activeTab === "Pending" || activeTab === "Ongoing" || activeTab === "Project Requests") && (
                    <td>{notifyUser ? "Yes" : "No"}</td>
                )}


                <td>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <a className="text-heading font-semibold cu1zo" onClick={() => handleEditClick('points')}>
                            {points}
                        </a>
                    </div>
                </td>


                {activeTab === "Project Requests" || activeTab === "Ongoing" || activeTab === "Pending" ? (

                    <td>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <a className="text-heading font-semibold cu1zo" onClick={() => handleEditClick('minXP')}>
                                {minXP}
                            </a>
                        </div>
                    </td>

                ) : null}

                <td>
                    <span className={`badge badge-lg badge-dot`}>
                        <i className={statusClass}></i>{status}
                    </span>
                </td>


                <td className="text-end">
                    <a href="#" className="btn btn-sm btn-neutral" onClick={onMainButtonClick}>{getButtonText()}</a>

                    <button type="button" onClick={handleDeleteClick} className="btn btn-sm btn-square btn-neutral text-danger-hover cvhuf">
                        <i className="bi bi-trash"></i>
                    </button>
                </td>

            </tr >

            {/* Number Input Modal */}

            < Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                heading={modalHeading}
                showInputField={true}
                inputPlaceholder="Enter value"
                inputType="number"
                onInputChange={handleInputChange} // For main input field
                primaryButtonText="Save"
                secondaryButtonText="Cancel"
                showButtons={true}
                onPrimaryClick={handleSave}
                onSecondaryClick={() => setShowModal(false)}
                showIDInputField={true} // Show Super Admin ID input
                IDinputPlaceholder="Enter Super Admin ID"
                IDinputType="text"
                onIDInputChange={(e) => setSuperAdminID(e.target.value)} // New prop for Super Admin ID input
            />

            {/* Moderator Input Modal */}

            <Modal
                show={showModeratorModal}
                onClose={() => setShowModeratorModal(false)}
                heading="Add Moderator"
                showInputField={true}
                inputPlaceholder="Enter moderator email"
                inputType="email"
                inputValue={newModeratorEmail}
                onInputChange={(e) => setNewModeratorEmail(e.target.value)}
                showIDInputField={true}
                IDinputPlaceholder="Enter Super Admin ID"
                IDinputType="text"
                onIDInputChange={(e) => setSuperAdminID(e.target.value)}
                primaryButtonText="Add"
                secondaryButtonText="Cancel"
                showButtons={true}
                onPrimaryClick={handleModeratorAdd}
                onSecondaryClick={() => {
                    setShowModeratorModal(false);
                    setNewModeratorEmail('');
                    setSuperAdminID('');
                }}
            />



            {/* Delete Modal */}


            <Modal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                heading="Delete Document?"
                body={`Are you sure you want to delete ${projectName}?`}
                primaryButtonText="Delete"
                secondaryButtonText="Cancel"
                showButtons={true}
                onPrimaryClick={activeTab === "Applications" ? handleApplicationDeletion : handleDeleteConfirm}
                onSecondaryClick={() => setShowDeleteModal(false)}
            />


            {/* View User Data Model */}


            <ScrollableModal
                show={showUserModal}
                onClose={() => {
                    setShowUserModal(false);
                }}
                heading={userModalHeading}
                body={userModalMessage}
                primaryButtonText={isRemovedUser ? "Add" : "Remove"}
                secondaryButtonText="Cancel"
                showButtons={true}
                onPrimaryButtonClicked={handleUserActionClick}
                onSecondaryButtonClicked={() => setShowUserModal(false)}
            />


        </>
    );
};

export default TableRow;