import React, { useState } from 'react';
import './Modal.css';
import { SUPER_ADMIN_ID } from '../../../data/data';

const Modal = ({
    show,
    onClose,
    heading,
    body,
    primaryButtonText = "OK",
    secondaryButtonText = "Cancel",
    showButtons = false,
    onPrimaryClick = () => { },
    onSecondaryClick = () => { },
    showInputField = false,
    showIDInputField = false,
    inputPlaceholder = "Enter your text",
    IDinputPlaceholder = "Enter Super Admin ID",
    inputType = "text",
    IDinputType = "text",
    onInputChange = () => { },
    onSuperAdminValidation = () => false,
}) => {

    const [superAdminID, setSuperAdminID] = useState('');


    if (!show) return null;



    const handlePrimaryClick = () => {
        if (showIDInputField && superAdminID !== SUPER_ADMIN_ID) {
            alert("Invalid Super Admin ID");
            return;
        }
        onPrimaryClick();
    };


    return (
        <div className='modal_wrapper'>
            <div className="modal-overlay">
                <div className="card">
                    <div className="card-content">
                        <p className="card-heading">{heading}</p>
                        <p className="card-description">{body}</p>
                        {showIDInputField && (
                            <div className="input-field-container">
                                <input
                                    type={IDinputType}
                                    className="input-field"
                                    placeholder={IDinputPlaceholder}
                                    onChange={(e) => setSuperAdminID(e.target.value)}
                                />
                            </div>
                        )}


                        {showInputField && (
                            <div className="input-field-container">
                                <input
                                    type={inputType}
                                    className="input-field"
                                    placeholder={inputPlaceholder}
                                    onChange={onInputChange}
                                />
                            </div>
                        )}
                    </div>
                    {showButtons && (
                        <div className="card-button-wrapper">
                            <button className="card-button secondary" onClick={onSecondaryClick}>
                                {secondaryButtonText}
                            </button>
                            <button className="card-button primary" onClick={handlePrimaryClick}>
                                {primaryButtonText}
                            </button>
                        </div>
                    )}
                    <button className="exit-button" onClick={onClose}>
                        <svg height="20px" viewBox="0 0 384 512">
                            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;