import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { skillOptions, projectRoleTypeData } from '../../data/data';
import OptionSelectionBox from "../OptionSelectionBox/OptionSelectionBox";

const FilterGroup = ({ filters, onFilterChange }) => {
    // State for each filter group
    const [projectStatus, setProjectStatus] = useState(filters.projectStatus);
    const [projectRoles, setProjectRoles] = useState(filters.projectRoles);
    const [techSkills, setTechSkills] = useState(filters.techSkills);
    const [projectBudget, setProjectBudget] = useState(filters.projectBudget);
    const [minXP, setMinXP] = useState(filters.minXP);
    const [isPaidOnlyToggleOn, setIsPaidOnlyToggleOn] = useState(filters.isPaidOnly);


    // State for option boxes
    const [showTechSkillsBox, setShowTechSkillsBox] = useState(false);
    const [showProjectRolesBox, setShowProjectRolesBox] = useState(false);

    // Default visible options
    const defaultTechSkills = ['React', 'Next.js', 'Flutter', 'React Native', 'Firebase', 'AWS', 'MongoDB', 'PostgreSQL'];


    // Update parent component whenever filters change
    useEffect(() => {
        onFilterChange({
            projectStatus,
            projectRoles,
            techSkills,
            projectBudget,
            minXP,
            isPaidOnly: isPaidOnlyToggleOn
        });
    }, [projectStatus, projectRoles, techSkills, projectBudget, minXP, isPaidOnlyToggleOn]);



    // Get visible skills (either default or selected non-default ones)
    const getVisibleSkills = () => {
        // Start with default skills
        let visibleSkills = [...defaultTechSkills];

        // Replace only unselected default skills with non-default selected skills
        techSkills.forEach(skill => {
            if (!defaultTechSkills.includes(skill)) {
                // Find the first unselected default skill position
                const unselectedIndex = visibleSkills.findIndex(vs => !techSkills.includes(vs));
                if (unselectedIndex !== -1) {
                    visibleSkills[unselectedIndex] = skill;
                }
            }
        });

        return visibleSkills;
    };

    // Default visible project roles (first 6)
    const defaultProjectRoles = projectRoleTypeData.slice(0, 6);

    // Get visible project roles (either default or selected non-default ones)
    const getVisibleProjectRoles = () => {
        // Start with default roles
        let visibleRoles = [...defaultProjectRoles];

        // Replace only unselected default roles with non-default selected roles
        projectRoles.forEach(role => {
            if (!defaultProjectRoles.includes(role)) {
                // Find the first unselected default role position
                const unselectedIndex = visibleRoles.findIndex(vr => !projectRoles.includes(vr));
                if (unselectedIndex !== -1) {
                    visibleRoles[unselectedIndex] = role;
                }
            }
        });

        return visibleRoles;
    };


    const handleProjectStatusChange = (item) => {
        if (projectStatus.includes(item)) {
            setProjectStatus([]); // Deselect if already selected
        } else {
            setProjectStatus([item]); // Select new item
        }
    };


    const handleProjectRolesChange = (item) => {
        const isSelected = projectRoles.includes(item);
        if (isSelected) {
            setProjectRoles(projectRoles.filter(role => role !== item));
        } else {
            if (projectRoles.length < 3) {
                setProjectRoles([...projectRoles, item]);
            } else {
                toast.warning("You can only select up to 3 options in Project Roles");
            }
        }
    };

    const handleTechSkillsChange = (item) => {
        const isSelected = techSkills.includes(item);
        if (isSelected) {
            setTechSkills(techSkills.filter(skill => skill !== item));
        } else {
            if (techSkills.length < 5) {
                setTechSkills([...techSkills, item]);
            } else {
                toast.warning("You can only select up to 5 options in Tech Skills");
            }
        }
    };



    // Modified handler for single-select project budget to allow deselection
    const handleProjectBudgetChange = (item) => {
        if (projectBudget.includes(item)) {
            setProjectBudget([]); // Deselect if already selected
        } else {
            setProjectBudget([item]); // Select new item
        }
    };

    // Modified handler for single-select minimum XP to allow deselection
    const handleMinXPChange = (item) => {
        if (minXP.includes(item)) {
            setMinXP([]); // Deselect if already selected
        } else {
            setMinXP([item]); // Select new item
        }
    };

    const handlePaidOnlyToggle = () => {
        setIsPaidOnlyToggleOn(!isPaidOnlyToggleOn);
    };

    const handleClearAll = () => {
        setProjectStatus([]);
        setProjectRoles([]);
        setTechSkills([]);
        setProjectBudget([]);
        setMinXP([]);
        setIsPaidOnlyToggleOn(false);
    };

    return (
        <div className="space-y-6">

            <div className="c6kdq cv23o cx5ip cv5zz">
                <button className="cf37z c4vrg cw2fq" onClick={handleClearAll}>Clear</button>
            </div>

            {/* Project Status */}
            <div>
                <div className="cd67x cf37z c3774 cznhr cd2ei">Project Status</div>
                <ul className="czodn">
                    {["Open", "Ongoing"].map((item, index) => (
                        <li key={index}>
                            <label className="c8og8 cduop">
                                <input
                                    type="checkbox"
                                    className="cicja"
                                    checked={projectStatus.includes(item)}
                                    onChange={() => handleProjectStatusChange(item)}
                                />
                                <span className="cvhuf cf37z cm87k">{item}</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Project Roles */}
            <div className="relative">
                <div className="cd67x cf37z c3774 cznhr">Project Type</div>
                <ul className="czodn">
                    {getVisibleProjectRoles().map((item, index) => (
                        <li key={index}>
                            <label className="c8og8 cduop">
                                <input
                                    type="checkbox"
                                    className="cicja"
                                    checked={projectRoles.includes(item)}
                                    onChange={() => handleProjectRolesChange(item)}
                                />
                                <span className="cvhuf cf37z cm87k">{item}</span>
                            </label>
                        </li>
                    ))}
                </ul>
                <button
                    className="cf37z c4vrg cw2fq cd2ei"
                    onClick={() => setShowProjectRolesBox(true)}
                >
                    Show More
                </button>
                {showProjectRolesBox && (
                    <div className="absolute z-50 bg-white shadow-lg rounded-lg p-4 mt-2 w-full">
                        <OptionSelectionBox
                            options={projectRoleTypeData}
                            selectedOptions={projectRoles}
                            setSelectedOptions={setProjectRoles}
                            onSave={() => setShowProjectRolesBox(false)}
                            maxSelection={3}
                        />
                    </div>
                )}
            </div>

            {/* Tech Skills */}
            <div className="relative">
                <div className="cd67x cf37z c3774 cznhr">Tech Skills</div>
                <ul className="czodn">
                    {getVisibleSkills().map((item, index) => (
                        <li key={index}>
                            <label className="c8og8 cduop">
                                <input
                                    type="checkbox"
                                    className="cicja"
                                    checked={techSkills.includes(item)}
                                    onChange={() => handleTechSkillsChange(item)}
                                />
                                <span className="cvhuf cf37z cm87k">{item}</span>
                            </label>
                        </li>
                    ))}
                </ul>
                <button
                    className="cf37z c4vrg cw2fq cd2ei"
                    onClick={() => setShowTechSkillsBox(true)}
                >
                    Show More
                </button>
                {showTechSkillsBox && (
                    <div className="absolute z-50 bg-white shadow-lg rounded-lg p-4 mt-2 w-full">
                        <OptionSelectionBox
                            options={skillOptions}
                            selectedOptions={techSkills}
                            setSelectedOptions={setTechSkills}
                            onSave={() => setShowTechSkillsBox(false)}
                            maxSelection={5}
                        />
                    </div>
                )}
            </div>

            {/* Project Budget */}
            <div>
                <div className="cd67x cf37z c3774 cznhr">Project Budget</div>
                <ul className="czodn">
                    {["FREE", "$", "$$", "$$$", "$$$$"].map((item, index) => (
                        <li key={index}>
                            <label className="c8og8 cduop">
                                <input
                                    type="checkbox"
                                    className="cicja"
                                    checked={projectBudget.includes(item)}
                                    onChange={() => handleProjectBudgetChange(item)}
                                />
                                <span className="cvhuf cf37z cm87k">{item}</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Minimum XP */}
            <div>
                <div className="cd67x cf37z c3774 cznhr cd2ei">Minimum XP</div>
                <ul className="czodn">
                    {["0", "1 - 10", "10 - 50", "50 - 100", "100 - 250", "250 - 500", "500 - 1000", "1000+"].map((item, index) => (
                        <li key={index}>
                            <label className="c8og8 cduop">
                                <input
                                    type="checkbox"
                                    className="cicja"
                                    checked={minXP.includes(item)}
                                    onChange={() => handleMinXPChange(item)}
                                />
                                <span className="cvhuf cf37z cm87k">{item}</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>

            <div>
                <div className="cd67x cf37z c3774 cznhr">Paid Projects Only</div>
                <div className="c8og8 cduop">
                    <div className="cumo3">
                        <input
                            type="checkbox"
                            id="remote-toggle"
                            className="cxbaz"
                            checked={isPaidOnlyToggleOn}
                            onChange={handlePaidOnlyToggle}
                        />
                        <label className="cg72j" htmlFor="remote-toggle">
                            <span className="cz0l8 ctpla" aria-hidden="true"></span>
                        </label>
                    </div>
                    <div className="cvhuf cf37z c75bs ccyd8">
                        {isPaidOnlyToggleOn ? 'On' : 'Off'}
                    </div>
                </div>
            </div>


        </div>
    );
};

export default FilterGroup;