import React from 'react';
import "./Loader.css";


const Loader = () => {
    return (

        <section class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </section>


    );
};

export default Loader;