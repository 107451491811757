// hooks/useUserProfile.js
import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebaseConfig/Firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

export const useUserProfile = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [selectedSkills, setSelectedSkills] = useState([]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userDocRef = doc(db, "Users", user.email);
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    setUserData({
                        ...userData,
                        DateOfBirth: userData.DateOfBirth ? userData.DateOfBirth.toDate().toISOString().split('T')[0] : '',
                        fullName: `${userData.FirstName} ${userData.LastName}`,
                    });
                    setSelectedSkills(userData.Skills || []);
                } else {
                    console.log("No such document!");
                }
            } else {
                navigate('/signin');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    return { userData, setUserData, selectedSkills, setSelectedSkills };
};
