// General Imports
import React, { useState, useEffect, useMemo } from 'react';

// Frontend Imports
import "../CSS/AdminDashboard.css";
import Loader from "../components/Loader/Loader";
import TableRow from "../components/TableRow/TableRow";
import StatsCard from "../components/StatsCard/StatsCard";
import SearchBar from "../components/SearchBar/SearchBar";
import Modal from '../components/General/Modal/Modal';
import ScrollableModal from '../components/General/ScrollableModal/ScrollableModal';
import { FaTimes } from 'react-icons/fa';
import AdminLogin from '../components/Admin/AdminLogin';
import AdminNav from '../components/Admin/AdminTabs';
import AdminHeader from '../components/Admin/AdminHeader';

// Backend Imports
import { doc, collection, query, where, onSnapshot, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig/Firebase";

// Data Imports
import { getCardsData } from '../utils/otherFunctions';
import { dummyDataRows } from '../data/data';

// Utility Imports
import { getStatusClass } from '../utils/otherFunctions';
import { getTableHeaders } from '../utils/otherFunctions';
import { handleProjectLinkClick, handleClientLinkClick, updateProjectStatus, handleApplicationClientClick, handleApplicationProjectClick, handleApplicantProfileClick, handleApplicationApproval, handleApplicationDeletion } from '../utils/adminDashboardUtils';
import { formatShortDateIndiaStyle } from '../utils/dateFormatting';

const AdminDashboard = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [superAdminID, setSuperAdminID] = useState('');
    const [activeTab, setActiveTab] = useState('Applications');
    const [activeTabCounter, setActiveTabCounter] = useState(0);


    const [projectRequests, setProjectRequests] = useState([]);
    const [pendingProjects, setPendingProjects] = useState([]);
    const [ongoingProjects, setOngoingProjects] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [showScrollableModal, setShowScrollableModal] = useState(false);
    const [modalHeading, setModalHeading] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const [searchTerm, setSearchTerm] = useState('');
    const [searchBy, setSearchBy] = useState('project');
    const [showFilterOption, setShowFilterOption] = useState(false);


    const [showSortTooltip, setShowSortTooltip] = useState(false);
    const [showFilterTooltip, setShowFilterTooltip] = useState(false);
    const [sortOption, setSortOption] = useState('');
    const [filterOption, setFilterOption] = useState('');
    const [applications, setApplications] = useState([]);

    const [isLoading, setIsLoading] = useState(false);




    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setShowFilterOption(tab === 'Ongoing' || tab === 'Applications');

        clearSort();
        clearFilter();
        setSearchTerm('');

        setActiveTabCounter(prevCount => prevCount + 1);
    };



    // Handle sorting logic
    const handleSort = (sortBy) => {
        setSortOption(sortBy);
        setShowSortTooltip(false);
    };

    // Handle filtering logic
    const handleFilter = (filterBy) => {
        setFilterOption(filterBy);
        setShowFilterTooltip(false);
    };

    // Clear sorting
    const clearSort = () => {
        setSortOption('');
        setShowSortTooltip(false);
    };

    // Clear filtering
    const clearFilter = () => {
        setFilterOption('');
        setShowFilterTooltip(false);
    };




    useEffect(() => {
        if (!isLoggedIn) return;

        // Applications counter applicationsUnsubscribe only when atleast once the tab is chnaged so that the application table loads as soon as the page is loaded
        let applicationsUnsubscribe;
        // Running the 
        if (activeTabCounter > 0) {
            applicationsUnsubscribe = onSnapshot(
                query(
                    collection(db, "Applications"),
                    where("isApproved", "==", false),
                    where("isDeleted", "==", false)
                ),
                (snapshot) => setApplications(snapshot.docs)
            );
        }

        // Project Requests counter
        const requestsUnsubscribe = onSnapshot(
            query(
                collection(db, "Projects"),
                where("status", "==", "Unverified")
            ),
            (snapshot) => setProjectRequests(snapshot.docs)
        );

        // Pending Projects counter
        const pendingUnsubscribe = onSnapshot(
            query(
                collection(db, "Projects"),
                where("status", "==", "Pending")
            ),
            (snapshot) => setPendingProjects(snapshot.docs)
        );

        // Ongoing Projects counter
        const ongoingUnsubscribe = onSnapshot(
            query(
                collection(db, "Projects"),
                where("status", "in", ["Ongoing", "Finished"])
            ),
            (snapshot) => setOngoingProjects(snapshot.docs)
        );

        return () => {
            if (applicationsUnsubscribe) applicationsUnsubscribe();
            requestsUnsubscribe();
            pendingUnsubscribe();
            ongoingUnsubscribe();
        };
    }, [isLoggedIn, activeTabCounter]);


    const cardsData = useMemo(() => {
        return getCardsData(
            applications.length,
            projectRequests.length,
            pendingProjects.length,
            ongoingProjects.length
        );
    }, [applications, projectRequests, pendingProjects, ongoingProjects]);



    //FETCH DATA

    useEffect(() => {
        if (activeTab === 'Applications') {
            // Set up the listener
            const unsubscribe = onSnapshot(
                query(
                    collection(db, "Applications"),
                    where("isApproved", "==", false),
                    where("isDeleted", "==", false)
                ),
                async (applicationsSnapshot) => {
                    const applicationsData = [];

                    // Loop through the snapshot documents
                    for (const appDoc of applicationsSnapshot.docs) {
                        const appData = appDoc.data();

                        // Fetch project data
                        const projectDoc = await getDoc(doc(db, "Projects", appData.projectID));
                        const projectData = projectDoc.data();

                        // Fetch user data
                        const userDoc = await getDoc(doc(db, "Users", appData.ApplicantEmail));
                        const userData = userDoc.data();

                        applicationsData.push({
                            id: appDoc.id,
                            projectName: appData.projectName,
                            clientName: projectData?.name || 'N/A',
                            applicantFullName: [userData?.fullName || ''],
                            date: appData.submittedOn,
                            applicantSkills: userData?.Skills || [],
                            applicantPoints: userData?.XP || 0,
                            status: projectData?.status || 'N/A'
                        });
                    }

                    // Update state with the new data
                    setApplications(applicationsData);
                }
            );

            // Cleanup the listener on unmount or if activeTab changes
            return () => unsubscribe();
        }
    }, [activeTab]);


    useEffect(() => {
        // Define the query to filter projects based on the active tab
        let q;
        switch (activeTab) {
            case "Project Requests":
                q = query(collection(db, "Projects"), where("status", "==", "Unverified"));
                break;
            case "Pending":
                q = query(collection(db, "Projects"), where("status", "==", "Pending"));
                break;
            case "Ongoing":
                q = query(collection(db, "Projects"), where("status", "in", ["Ongoing", "Finished"]));
                break;
            default:
                return;
        }

        // Use onSnapshot to listen for real-time updates
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const projects = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));


            // Update the corresponding state based on the active tab
            switch (activeTab) {
                case "Project Requests":
                    setProjectRequests(projects);
                    break;
                case "Pending":
                    setPendingProjects(projects);
                    break;
                case "Ongoing":
                    setOngoingProjects(projects);
                    break;
                default:
                    return;
            }
        }, (error) => {
            console.error("Error fetching projects: ", error);
        });

        // Cleanup the listener when the component unmounts or when activeTab changes
        return () => unsubscribe();
    }, [activeTab]);


    //SEARCH DATA AND SHOW NON DELETED PROJECTS  


    const filteredProjects = useMemo(() => {
        let projectsToFilter;

        switch (activeTab) {
            case 'Applications':
                projectsToFilter = applications;
                break;
            case 'Project Requests':
                projectsToFilter = projectRequests;
                break;
            case 'Pending':
                projectsToFilter = pendingProjects;
                break;
            case 'Ongoing':
                projectsToFilter = ongoingProjects;
                break;
            default:
                projectsToFilter = [];
        }

        // Filter out documents where isDeletedByAdmin is true
        projectsToFilter = projectsToFilter.filter(project => !project.isDeletedByAdmin);

        // Apply search filter
        projectsToFilter = projectsToFilter.filter(project => {
            const searchField = searchBy === 'project'
                ? (project.projectName || project.projectTitle)
                : (project.clientName || project.name);
            return searchField?.toLowerCase().includes(searchTerm.toLowerCase());
        });

        // Apply filter for status (Ongoing or Finished)
        if (activeTab === 'Ongoing' && filterOption) {
            projectsToFilter = projectsToFilter.filter(project => project.status === filterOption);
        }

        // Apply filter for status (Pending or Ongoing) for Applications tab
        if (activeTab === 'Applications' && filterOption) {
            projectsToFilter = projectsToFilter.filter(project => project.status === filterOption);
        }


        // Apply sorting for application tab
        if (activeTab === 'Applications' && sortOption) {
            projectsToFilter = projectsToFilter.sort((a, b) => {
                if (sortOption === 'recent') {
                    return b.date - a.date; // Recent to old
                } else if (sortOption === 'old') {
                    return a.date - b.date; // Old to recent
                } else if (sortOption === 'high') {
                    return b.applicantPoints - a.applicantPoints; // Points High to Low
                } else if (sortOption === 'low') {
                    return a.applicantPoints - b.applicantPoints; // Points Low to High
                }
                return 0;
            });
        }

        // Apply sorting for other tabs
        if (['Pending', 'Ongoing', 'Project Requests'].includes(activeTab) && sortOption) {
            projectsToFilter = projectsToFilter.sort((a, b) => {
                if (sortOption === 'recent') {
                    return b.createdAt.seconds - a.createdAt.seconds; // Recent to old
                } else if (sortOption === 'old') {
                    return a.createdAt.seconds - b.createdAt.seconds; // Old to recent
                } else if (sortOption === 'high') {
                    return b.budget - a.budget; // High to low price
                } else if (sortOption === 'low') {
                    return a.budget - b.budget; // Low to high price
                }
                return 0;
            });
        }

        return projectsToFilter;
    }, [activeTab, applications, projectRequests, pendingProjects, ongoingProjects, searchTerm, searchBy, sortOption, filterOption]);



    return (

        <>

            {!isLoggedIn ? (
                <AdminLogin onLoginSuccess={() => setIsLoggedIn(true)} />
            ) : (

                <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                    {/* <!-- Main content  --> */}
                    <div class="h-screen flex-grow-1 overflow-y-lg-auto">
                        {/* <!-- Header  --> */}
                        <header class="bg-surface-primary border-bottom pt-6">
                            <div class="container-fluid">
                                <div class="mb-npx">
                                    <AdminHeader />
                                    {/* <!-- Nav  --> */}
                                    <AdminNav activeTab={activeTab} handleTabChange={handleTabChange} />
                                </div>
                            </div>
                        </header>
                        {/* <!-- Main  --> */}
                        <main class="py-6 bg-surface-secondary">
                            <div class="container-fluid">

                                {/* <!-- Card stats  --> */}

                                <div className="row g-6 mb-6">
                                    {cardsData.map((card, index) => (
                                        <StatsCard key={index} {...card} />
                                    ))}
                                </div>

                                {/* Search Bar */}

                                <div className="mb-4">

                                    <div className='searchbar-wrapper-admin d-flex align-items-start'>
                                        <SearchBar
                                            placeholder={`Search by ${searchBy}...`}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            showFilterOptions={showFilterOption}
                                            onClickSortOptions={() => setShowSortTooltip(!showSortTooltip)}
                                            onClickFilterOptions={() => setShowFilterTooltip(!showFilterTooltip)}
                                            showFilterDot={!!filterOption}
                                            showSortDot={!!sortOption}
                                        />

                                        {/* Sort Tooltip */}
                                        {showSortTooltip && (
                                            activeTab === 'Applications' ? (
                                                <div className="tooltip-box" style={{ marginTop: '10px' }}>
                                                    <div className="tooltip-header">
                                                        <span>Sort Options</span>
                                                        <button className="close-btn" onClick={() => setShowSortTooltip(false)}> <FaTimes size={20} /></button>
                                                    </div>
                                                    <div className="tooltip-options">
                                                        <button onClick={() => handleSort('recent')}>Recent to Old</button>
                                                        <button onClick={() => handleSort('old')}>Old to Recent</button>
                                                        <button onClick={() => handleSort('high')}>Points High to Low</button>
                                                        <button onClick={() => handleSort('low')}>Points Low to High</button>
                                                        <button className="clear-btn" onClick={() => clearSort()}><b>Clear</b></button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="tooltip-box" style={{ marginTop: '10px' }}>
                                                    <div className="tooltip-header">
                                                        <span>Sort Options</span>
                                                        <button className="close-btn" onClick={() => setShowSortTooltip(false)}> <FaTimes size={20} /></button>
                                                    </div>
                                                    <div className="tooltip-options">
                                                        <button onClick={() => handleSort('recent')}>Recent to Old</button>
                                                        <button onClick={() => handleSort('old')}>Old to Recent</button>
                                                        <button onClick={() => handleSort('high')}>Price High to Low</button>
                                                        <button onClick={() => handleSort('low')}>Price Low to High</button>
                                                        <button className="clear-btn" onClick={() => clearSort()}><b>Clear</b></button>
                                                    </div>
                                                </div>
                                            )
                                        )}

                                        {/* Filter Tooltip */}
                                        {showFilterTooltip && (
                                            <div className="tooltip-box" style={{ marginTop: '10px' }}>
                                                <div className="tooltip-header">
                                                    <span>Filter Options</span>
                                                    <button className="close-btn" onClick={() => setShowFilterTooltip(false)}> <FaTimes size={20} /></button>
                                                </div>
                                                <div className="tooltip-options">
                                                    {activeTab === 'Applications' ? (
                                                        <>
                                                            <button onClick={() => handleFilter('Pending')}>Pending</button>
                                                            <button onClick={() => handleFilter('Ongoing')}>Ongoing</button>
                                                            <button className="clear-btn" onClick={() => clearFilter()}> <b>Clear</b></button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button onClick={() => handleFilter('Ongoing')}>Ongoing</button>
                                                            <button onClick={() => handleFilter('Finished')}>Finished</button>
                                                            <button className="clear-btn" onClick={() => clearFilter()}> <b>Clear</b></button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}


                                    </div>

                                    <div className="mt-2 d-flex align-items-center">
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="searchBy"
                                                id="searchByProject"
                                                value="project"
                                                checked={searchBy === 'project'}
                                                onChange={() => setSearchBy('project')}
                                            />
                                            <label className="form-check-label" htmlFor="searchByProject">
                                                Project
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="searchBy"
                                                id="searchByClient"
                                                value="client"
                                                checked={searchBy === 'client'}
                                                onChange={() => setSearchBy('client')}
                                            />
                                            <label className="form-check-label" htmlFor="searchByClient">
                                                Client
                                            </label>
                                        </div>
                                    </div>

                                    <div className="d-flex">


                                    </div>

                                </div>


                                {/* Main Table Area */}


                                <div className="card shadow border-0 mb-7">
                                    <div className="card-header">
                                        <h5 className="mb-0">{activeTab}</h5>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-nowrap">

                                            <thead className="thead-light">
                                                {getTableHeaders(activeTab)}
                                            </thead>

                                            <tbody>
                                                {activeTab === 'Applications' && filteredProjects.map(app => (
                                                    <TableRow
                                                        key={app.id}
                                                        projectId={app.id}
                                                        projectName={app.projectName}
                                                        applicantNames={app.applicantFullName}
                                                        clientName={app.clientName}
                                                        date={formatShortDateIndiaStyle(app.date)}
                                                        skills={app.applicantSkills}
                                                        points={app.applicantPoints}
                                                        status={app.status}
                                                        statusClass={getStatusClass(app.status)}
                                                        activeTab={activeTab}
                                                        onMainButtonClick={(e) => handleApplicationApproval(app.id, e)}
                                                        onProjectClick={() => handleApplicationProjectClick(app.id, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        onClientClick={() => handleApplicationClientClick(app.id, setModalHeading, setModalMessage, setShowScrollableModal)}




                                                    />
                                                ))}
                                                {activeTab === 'Pending' && filteredProjects.map(project => (
                                                    <TableRow
                                                        key={project.id}
                                                        projectId={project.id}
                                                        projectName={project.projectTitle}
                                                        onProjectClick={() => handleProjectLinkClick(project, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        clientName={project.name}
                                                        onClientClick={() => handleClientLinkClick(project, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        date={formatShortDateIndiaStyle(project.createdAt)}
                                                        budget={project.budget}
                                                        skills={project.techStack}
                                                        notifyUser={project.isNotifyPostonEmail}
                                                        points={project.points}
                                                        minXP={project.minXP}
                                                        status={project.status}
                                                        statusClass={getStatusClass(project.status)}
                                                        activeTab={activeTab}
                                                        moderatorNames={project.moderators}
                                                        applicantNames={project.selectedApplicants}
                                                        removedApplicantNames={project.removedApplicants}
                                                        removedModerators={project.removedModerators}
                                                        onMainButtonClick={() => updateProjectStatus(project.id, "Ongoing")}
                                                    />


                                                ))}
                                                {activeTab === 'Ongoing' && filteredProjects.map(project => (
                                                    <TableRow
                                                        key={project.id}
                                                        projectId={project.id}
                                                        projectName={project.projectTitle}
                                                        onProjectClick={() => handleProjectLinkClick(project, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        clientName={project.name}
                                                        onClientClick={() => handleClientLinkClick(project, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        date={formatShortDateIndiaStyle(project.createdAt)}
                                                        budget={project.budget}
                                                        skills={project.techStack}
                                                        notifyUser={project.isNotifyPostonEmail}
                                                        points={project.points}
                                                        minXP={project.minXP}
                                                        status={project.status}
                                                        statusClass={getStatusClass(project.status)}
                                                        activeTab={activeTab}
                                                        moderatorNames={project.moderators}
                                                        applicantNames={project.selectedApplicants}
                                                        removedApplicantNames={project.removedApplicants}
                                                        removedModerators={project.removedModerators}
                                                        onMainButtonClick={() => updateProjectStatus(project.id, "Finished")}
                                                    />
                                                ))}
                                                {activeTab === 'Project Requests' && filteredProjects.map(project => (
                                                    <TableRow
                                                        key={project.id}
                                                        projectId={project.id}
                                                        projectName={project.projectTitle}
                                                        onProjectClick={() => handleProjectLinkClick(project, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        clientName={project.name}
                                                        onClientClick={() => handleClientLinkClick(project, setModalHeading, setModalMessage, setShowScrollableModal)}
                                                        date={formatShortDateIndiaStyle(project.createdAt)}
                                                        budget={project.budget}
                                                        skills={project.techStack}
                                                        notifyUser={project.isNotifyPostonEmail}
                                                        points={project.points}
                                                        minXP={project.minXP}
                                                        status={project.status}
                                                        statusClass={getStatusClass(project.status)}
                                                        activeTab={activeTab}
                                                        onMainButtonClick={() => updateProjectStatus(project.id, "Pending")}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>


                                        <Modal
                                            show={showModal}
                                            onClose={() => {
                                                setShowModal(false);
                                            }}
                                            heading={modalHeading}
                                            body={modalMessage}
                                            primaryButtonText="OK"
                                            secondaryButtonText="Cancel"
                                            showButtons={false}
                                            onPrimaryClick={() => setShowModal(false)}
                                            onSecondaryClick={() => setShowModal(false)}
                                        />



                                        <ScrollableModal
                                            show={showScrollableModal}
                                            onClose={() => {
                                                setShowScrollableModal(false);
                                            }}
                                            heading={modalHeading}
                                            body={modalMessage}
                                            primaryButtonText="Aloo"
                                            secondaryButtonText="Cancel"
                                            showButtons={true}
                                            onPrimaryClick={() => { }}
                                            onSecondaryClick={() => { }}
                                        />

                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

            )}



        </>



    );
};

export default AdminDashboard;