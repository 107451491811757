import { toast } from 'react-toastify';
import { maxLengthChecksforProfile } from '../data/data';

export const validateProfileInputs = (userData, selectedSkills) => {
    const requiredFields = [
        'FirstName', 'LastName', 'Email', 'Mobile', 'ResumeLink', 'Designation',
        'AboutMe', 'MyExperience', 'PastProjects', 'GithubLink',
        'DateOfBirth', 'Gender'
    ];

    for (let field of requiredFields) {
        if (!userData[field] || userData[field].trim() === '') {
            toast.warning(`Please fill the ${field} field.`);
            return false;
        }
    }

    if (selectedSkills.length === 0) {
        toast.warning("Please select at least one skill.");
        return false;
    }

    for (let field in maxLengthChecksforProfile) {
        if (userData[field] && userData[field].length > maxLengthChecksforProfile[field]) {
            toast.error("Input cannot be more than " + maxLengthChecksforProfile[field] + " characters.");
            return false;
        }
    }

    const nameRegex = /^[a-zA-Z0-9]+$/;
    if (!nameRegex.test(userData.FirstName) || !nameRegex.test(userData.LastName)) {
        toast.warning("First name and Last name cannot contain special characters or spaces.");
        return false;
    }

    return true;
};
