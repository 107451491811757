import React from 'react';
import signinIllustration from '../../images/signin/auth-illustration.svg';

const SideTestimonial = ({ testimonials }) => {
    return (
        <div className="cj019 cqmv3 chrmv cg1xx cyzod cndjv ceq8v c753i" aria-hidden="true">
            {/* Bg */}
            <div className="cetod cfcbm ctjxe c6kdq cjcd5 cjxga c8f1b" aria-hidden="true"></div>

            {/* Illustration */}
            <div className="cetod c9rix c6kdq cyzod cndjv c8f1b" aria-hidden="true">
                <img src={signinIllustration} className="co24w" width="1440" height="900" alt="Page Illustration" />
            </div>

            {/* Quotes */}
            <div className="c3tj1 c6kdq ca7zr cjxga c8og8">
                <div className="cwkio cn4py">
                    <div className="cm1jm cfd1l cn582">
                        <div className="cb7bz c6kou">
                            {testimonials.map((testimonial, index) => (
                                <div key={index} className={`cdrqk crhpi comjk cmwpt c7z1d ${index % 2 === 0 ? 'c3z1d' : ''} cww2f c0s3t cqwhl ct0d8`}>
                                    <div className="cduop cv4ej c8og8">
                                        <div className="cwq9z ctzpc">
                                            <img className="c1g5q" src={testimonial.image} width="88" height="88" alt={`Testimonial ${index + 1}`} />
                                            <svg className="cflao c6kdq cyzod c753i" width="26" height="17" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 16.026h8.092l6.888-16h-4.592L0 16.026Zm11.02 0h8.092L26 .026h-4.65l-10.33 16Z"></path>
                                            </svg>
                                        </div>
                                        <figure>
                                            <blockquote className="clt06 cmn18 cg9ly">
                                                <p>{testimonial.quote}</p>
                                            </blockquote>
                                            <figcaption className="c4vrg cf37z">{testimonial.name}, {testimonial.designation} at <a className="c4von cpjz5" href="#0">{testimonial.institution}</a></figcaption>
                                        </figure>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideTestimonial;